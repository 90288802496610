import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { DataGridProProps, GridFilterModel } from "@mui/x-data-grid-pro"

import { ListPage } from "src/components/ListPage"
import { CommunityPostDetailModal } from "src/modals/community-post-detail"
import { ConfirmModal } from "src/modals/confirm"
import { ViewCommunityPostModal } from "src/modals/view-community-post"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { FilterModel, SortModel } from "src/types/data-grid-pro"
import { Repository } from "src/types/channel"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { IPostAndCommentsItem } from "src/views/community-posts-and-comments/type"
import { CommunityPostsAndCommentsStore } from "src/views/community-posts-and-comments/store"
import useCommunityPostsColumns from "src/views/community-posts-and-comments/hooks/useCommunityPostsColumns"
import { CustomGridTreeDataGroupingCell } from "src/views/community-posts-and-comments/components/CustomGridTreeDataGroupingCell"
import MultiselectFlagsFilter from "src/views/community-posts-and-comments/components/MultiselectFlagsFilter"
import { IPageFilterProps } from "src/components/PageFilter"
import { IPageHeaderProps } from "src/components/PageHeader"
import useRouteParams from "src/views/community-posts-and-comments/hooks/useRouteParams"

const REPOSITORY: Repository = "community-posts-and-comments"

export interface IConfirmModalProps {
    onConfirm: (confirmed: boolean) => Promise<void>
    title: string
    content: string
}

const View = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)
    const globalStore = useStore(GlobalStore)
    const columns = useCommunityPostsColumns()
    const allParams = useRouteParams()

    const advanceQuery = useMemo(
        () => ({
            sort: getSortModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridSortModel,
            ),
            filter: getFilterModelForRepository(
                REPOSITORY,
                globalStore.session.dataGridFilterModel,
            ),
        }),
        [
            globalStore.session.dataGridSortModel,
            globalStore.session.dataGridFilterModel,
        ],
    )

    useEffect(() => {
        ;(async () => {
            await store.init(globalStore.session.accessGroupId, advanceQuery)
        })()
        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroupId, store.selectedFlagFilter])

    const getDataGridInitialFilteringState = useCallback(():
        | GridFilterModel
        | undefined => {
        if (allParams.length === 0) return undefined

        const params = store.handleParams(columns, allParams)
        if (params?.length === 0) return undefined

        return { items: params }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, allParams])

    const handleFilterChange = useCallback(
        async (model: FilterModel) =>
            await store.query({ ...advanceQuery, filter: model }),
        [store, advanceQuery],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) =>
            await store.query({ ...advanceQuery, sort: model }),
        [store, advanceQuery],
    )

    const handleDeleteConfirm = useCallback(
        (item: IPostAndCommentsItem) => async (confirmed: boolean) => {
            if (confirmed) {
                const isPost = item.itemType === "post"
                isPost
                    ? await store.deletePost(item?.original_id)
                    : await store.deleteComment(item?.original_id)
            }
        },
        [store],
    )

    const openModal = useCallback(
        (
            ModalComponent: React.ComponentType<IConfirmModalProps>,
            props: IConfirmModalProps,
        ) => {
            globalStore.modals.open(() => <ModalComponent {...props} />, {
                variant: "slide-up-w600",
            })
        },
        [globalStore.modals],
    )

    const openDeleteConfirmModal = useCallback(
        (item: IPostAndCommentsItem) => () => {
            const isPost = item.itemType === "post"
            openModal(ConfirmModal, {
                onConfirm: handleDeleteConfirm(item),
                title: isPost
                    ? t`community-posts-view.delete-post-confirm-modal.title`
                    : t`community-posts-comments.delete-comment`,
                content: isPost
                    ? t`community-posts-view.delete-post-confirm-modal.content`
                    : t`community-posts-comments.are-delete-comment?`,
            })
        },
        [openModal, handleDeleteConfirm],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            globalStore.modals.open(() => (
                <ViewCommunityPostModal
                    id={
                        item.row["itemType"] === "post"
                            ? item.row["original_id"]
                            : item.row["parent_id"]
                    }
                />
            ))
        },
        [globalStore.modals],
    )

    const handleOrReportPostCommentAction = useCallback(
        (action: "report" | "handle", item: IPostAndCommentsItem) =>
            async (confirmed: boolean) => {
                globalStore.modals.pop()
                if (confirmed) {
                    const isPost = item.itemType === "post"
                    if (action === "report") {
                        isPost
                            ? await store.handledReportPost(item.original_id)
                            : await store.handledReportComment(item.original_id)
                    } else {
                        isPost
                            ? await store.handledPost(
                                  item.original_id,
                                  item.is_handled,
                              )
                            : await store.handledComment(
                                  item.original_id,
                                  item.is_handled,
                              )
                    }
                }
            },
        [globalStore.modals, store],
    )

    const openActionConfirmModal = useCallback(
        (action: "report" | "handle", item: IPostAndCommentsItem) => () => {
            const isPost = item.itemType === "post"
            const title =
                action === "report"
                    ? isPost
                        ? t`community-posts-comments.report-post`
                        : t`community-posts-comments.report-comment`
                    : isPost
                    ? item.is_handled
                        ? t`community-posts-comments.mark-unhandled-post`
                        : t`community-posts-comments.mark-handled-post`
                    : item.is_handled
                    ? t`community-posts-comments.mark-unhandled-comment`
                    : t`community-posts-comments.mark-handled-comment`

            const content =
                action === "report"
                    ? isPost
                        ? t`community-posts-comments.are-report-post?`
                        : t`community-posts-comments.are-report-comment?`
                    : isPost
                    ? item.is_handled
                        ? t`community-posts-comments.are-mark-unhandled-post?`
                        : t`community-posts-comments.are-mark-handled-post?`
                    : item.is_handled
                    ? t`community-posts-comments.are-mark-unhandled-comment?`
                    : t`community-posts-comments.are-mark-handled-comment?`

            openModal(ConfirmModal, {
                onConfirm: handleOrReportPostCommentAction(action, item),
                title,
                content,
            })
        },
        [openModal, handleOrReportPostCommentAction],
    )

    const renderActionOptions = useCallback(
        (item: IPostAndCommentsItem) => [
            {
                text:
                    item.itemType === "post"
                        ? t`community-posts-comments.report-post`
                        : t`community-posts-comments.report-comment`,
                onClick: openActionConfirmModal("report", item),
            },
            {
                text:
                    item.itemType === "post"
                        ? item.is_handled
                            ? t`community-posts-comments.mark-unhandled-post`
                            : t`community-posts-comments.mark-handled-post`
                        : item.is_handled
                        ? t`community-posts-comments.mark-unhandled-comment`
                        : t`community-posts-comments.mark-handled-comment`,
                onClick: openActionConfirmModal("handle", item),
            },
            {
                text:
                    item.itemType === "post"
                        ? t`community-posts-view.delete-post-button`
                        : t`community-posts-comments.delete-comment`,
                destructive: true,
                onClick: openDeleteConfirmModal(item),
            },
        ],
        [openActionConfirmModal, openDeleteConfirmModal],
    )

    const filter: IPageFilterProps = useMemo(
        () => ({
            actions: <MultiselectFlagsFilter />,
        }),
        [],
    )

    const groupingColDef: DataGridProProps["groupingColDef"] = {
        headerName: "",
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
        minWidth: 40,
        maxWidth: 40,
        disableReorder: true,
        hideable: false,
    }
    const header: IPageHeaderProps = useMemo(
        () => ({
            header: t`community-posts-view.header`,
            breadcrumbs: [
                t`community-posts-view.community-breadcrumb`,
                t`community-posts-comments.posts-breadcrumb`,
            ],
            createOptions: {
                onClick() {
                    globalStore.modals.open(() => <CommunityPostDetailModal />)
                },
                item: t`community-posts-view.create-community-post`,
            },
        }),
        [globalStore.modals],
    )
    const loading =
        globalStore.loading.is(
            CommunityPostsAndCommentsStore.LoadingKeys.init,
        ) || !store.posts.meta.initialized

    return (
        <ListPage header={header} loading={loading} filter={filter}>
            <DataGridProTable
                paginator={store.posts}
                data={store.posts.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={REPOSITORY}
                rowActionsRenderer={renderActionOptions}
                loading={globalStore.loading.is(
                    CommunityPostsAndCommentsStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                treeData={true}
                groupingColDef={groupingColDef}
                filteringInitialState={getDataGridInitialFilteringState()}
                rowHeight={60}
            />
        </ListPage>
    )
})

export const CommunityPostsAndCommentsView = () => (
    <StoreProvider Store={CommunityPostsAndCommentsStore}>
        <View />
    </StoreProvider>
)
