import React from "react"
import { makeAutoObservable } from "mobx"

import { Pagination } from "src/lib/pagination"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { CommunityAdminService } from "src/api"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { reportUnhandledApiError } from "src/lib/report"
import { IAdvanceQueryModel, IColumn } from "src/types/data-grid-pro"
import {
    IPostAndCommentsFilter,
    IPostAndCommentsItem,
    IPostInitialStateFilterItem,
    IRouteParams,
} from "src/views/community-posts-and-comments/type"
import { createItemsFromPost } from "src/views/community-posts-and-comments/helpers/createUnifiedItem"

export class CommunityPostsAndCommentsStore implements IDisposable {
    //#region Observables
    static Context = React.createContext<CommunityPostsAndCommentsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys(
        "init",
        "loading",
        "delete-post",
        "loaded",
        "delete-comment",
    )

    private repositoryChangeListenerDisposer?: () => void
    private accessGroupId = DEFAULT_ACCESS_GROUP.id

    gridInitialStatePro: IPostInitialStateFilterItem[] = []
    selectedFlagFilter: IPostAndCommentsFilter[] = []

    posts = new Pagination(
        async (params) => {
            const response =
                await CommunityAdminService.postV1AdminCommunityPostList({
                    request: {
                        flags:
                            this.selectedFlagFilter.length === 0
                                ? undefined
                                : this.selectedFlagFilter.map(
                                      (filter) => filter.id,
                                  ),
                        access_group_id: this.getAccessGroupId(),
                        page_number: params.page,
                        page_size: params.pageSize,
                        gridfilter: {
                            sort: params.advanceQuery?.sort,
                            items: params.advanceQuery?.items,
                            logic_operator: params.advanceQuery?.logicOperator,
                        },
                    },
                })

            // create unified items for post and comments
            const rows = (response.posts ?? []).flatMap(createItemsFromPost)

            return {
                items: rows,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: CommunityPostsAndCommentsStore.LoadingKeys.loading,
        },
    )
    //#endregion

    get gridInitialState() {
        return this.gridInitialStatePro
    }

    constructor() {
        makeAutoObservable(this)
    }

    //#region Async methods
    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery: IAdvanceQueryModel) {
        this.setAccessGroupId(accessGroupId)
        await this.posts.loadInitialPage(advanceQuery)
        this.listenToRepositoryChanges()
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.posts.loadAdvanceQuery(advanceQuery)
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["delete-post"])
    async deletePost(id: number) {
        try {
            await CommunityAdminService.deleteV1AdminCommunityPost({
                postId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "delete",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    @loads(() => CommunityPostsAndCommentsStore.LoadingKeys["delete-comment"])
    async deleteComment(id: number | undefined) {
        if (id === undefined) return

        try {
            await CommunityAdminService.deleteV1AdminCommunityComment({
                commentId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "update",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    async handledPost(id: number | undefined, isHandled: boolean) {
        if (id === undefined) return

        try {
            isHandled
                ? await CommunityAdminService.postV1AdminCommunityPostUnhandle({
                      postId: id,
                  })
                : await CommunityAdminService.postV1AdminCommunityPostHandle({
                      postId: id,
                  })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "update",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    async handledComment(id: number | undefined, isHandled: boolean) {
        if (id === undefined) return

        try {
            isHandled
                ? await CommunityAdminService.postV1AdminCommunityCommentUnhandle(
                      {
                          commentId: id,
                      },
                  )
                : await CommunityAdminService.postV1AdminCommunityCommentHandle(
                      {
                          commentId: id,
                      },
                  )

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "update",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    async handledReportPost(id: number | undefined) {
        if (id === undefined) return

        try {
            await CommunityAdminService.postV1AdminCommunityPostReport({
                postId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "update",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }
    async handledReportComment(id: number | undefined) {
        if (id === undefined) return

        try {
            await CommunityAdminService.postV1AdminCommunityCommentReport({
                commentId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-posts-and-comments",
                    action: "update",
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }
    //#endregion

    private getAccessGroupId() {
        return this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this.accessGroupId
            : undefined
    }

    //#region Private method
    private setAccessGroupId(accessGroupId: number) {
        this.accessGroupId = accessGroupId
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community-posts-and-comments"
                ) {
                    if (
                        event.payload.action === "update" ||
                        event.payload.action === "delete"
                    ) {
                        await this.posts.reload()
                    } else {
                        await this.posts.loadInitialPage()
                    }
                }
            },
        )
    }
    //#endregion

    //#region Public methods
    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    canEditPost(postId: number, adminId?: number) {
        const post = this.posts.items.find(
            (item) => item.original_id === postId,
        )
        if (post == null) {
            return false
        }

        return (
            post.authorObjectType === "admin_user" &&
            post.authorObjectId === adminId
        )
    }

    setInitialStateFilters(filters: IPostInitialStateFilterItem[]) {
        this.gridInitialStatePro = filters
    }

    setFlagFilter(flagFilter: IPostAndCommentsFilter[]) {
        this.selectedFlagFilter = flagFilter
    }

    handleParams(
        columnHeaders: IColumn<IPostAndCommentsItem>[],
        params: IRouteParams[] = [],
    ): IPostInitialStateFilterItem[] {
        //NOTE(Luis): we might refine and improve this method
        // to use it in a more global scope like inside the data-grid-pro
        const gridColumnHeaders = columnHeaders.filter(
            (column) =>
                column.filterable !== false && column.type !== undefined,
        )
        const filters: IPostInitialStateFilterItem[] = []

        params.forEach((param) => {
            const paramInColumn = gridColumnHeaders.find(
                (column) => column.field === param.param,
            )

            if (paramInColumn != null) {
                const { field, type } = paramInColumn
                const isPostId = field === "post_id"
                const operator = isPostId
                    ? "isAnyOf"
                    : type === "number"
                    ? "="
                    : type === "boolean"
                    ? "is"
                    : "contains"
                const value = isPostId ? JSON.parse(param.value) : param.value

                const filterItem: IPostInitialStateFilterItem = {
                    field: param.param,
                    operator,
                    value,
                }
                filters.push(filterItem)
            }
        })

        return filters
    }
    //#endregion
}
